import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { persistStore, persistReducer, Persistor } from 'redux-persist'

import { getConfig } from '../config'
import { RootState, createRootReducer, rootEpic } from '../state'

import persistConfig from './persist'

const epicMiddleware = createEpicMiddleware()

export default function configureStore(history: History): { store: Store; persistor: Persistor } {
  const persistedReducer = persistReducer<RootState>(persistConfig, createRootReducer(history))

  let middleware = applyMiddleware(epicMiddleware, routerMiddleware(history))
  if (getConfig().environment === 'development') {
    middleware = composeWithDevTools(middleware)
  }

  const store = createStore(persistedReducer, middleware) as Store<RootState>

  const persistor = persistStore(store)

  epicMiddleware.run(rootEpic as any)

  return {
    store,
    persistor,
  }
}
