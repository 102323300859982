import './hhg.css'
import headerImage from './background.png'

export const theme = {
  primaryText: '#000000',
  secondaryText: '#7f7f7f',
  invertText: '#F0F0F0',
  headerText: 'rgb(65, 65, 65)',
  accentColor: '#ffffff',
  selectionColor: '#0066B3',
  borderColor: '#D3D3D3',
  background: '#f8f7f6',
  contentBackground: '#ffffff',
  errorColor: '#e4042d',
  headerImage,
}
