import { I18n } from './'

export interface Location {
  name: string
  latitude: number
  longitude: number
}

export enum TimeOfDay {
  All = 'all',
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export interface SearchParams {
  date: Date | null
  practitioner: number | null
  location?: Location[]
  service?: number | null
  type?: number | null
}

export interface SearchTerms {
  date: Date
  timeOfDay: TimeOfDay
  practitioner: number | null
  query: string | null
  location: Location[]
  service: number | null
  type?: number | null
}

export interface AppointmentPrice {
  id: number
  price: number | null
  length: number
  medicalServiceId: number
  timeslotTypeId: number
}

export interface AppointmentType {
  timeslotTypeId: number
  timeslotTypeName: I18n
  onlineBookingVisibility: boolean
}

export type ObjectType = 'practitioner' | 'resource'

export interface Appointment {
  timeslotId: number
  location: {
    locationId: number
    locationName: I18n
  }
  objectDisplayName: I18n
  objectPrimaryMedicalServiceName: I18n
  objectPrimaryMedicalServiceId: number
  objectType: ObjectType
  objectId: number
  start: string
  end: string
  distance: number
  prices: AppointmentPrice[]
  duration: number
  isReserved: boolean
}

export interface Reservation extends Appointment {
  reservation: {
    medicalServiceId: number | null
    priceId: number | null
    timeslotTypeId: number | null
    reason: string | null
  } | null
  onlineBookingVisibility: boolean
  isExternalObject: boolean
}

export interface GroupedAppointmentLocation {
  name: I18n
  distance: number
}

export type ServicePriceType = number | null

export interface GroupedAppointment {
  practitioner: I18n
  objectId: number
  objectType: ObjectType
  medicalService: I18n
  location: GroupedAppointmentLocation[]
  distance: number
  price: ServicePriceType[] | null
  appointments: Appointment[]
}

export interface CalendarSlot {
  date: Date
  hasTimeslots: boolean
  areAllReserved: boolean
  hasFreeTimes: boolean
}

export interface Practitioner {
  id: number
  suggestion: I18n
  additionalInfo: I18n
}

export interface Service {
  medicalServiceId: number
  medicalServiceName: I18n
}

export type HTTPRequestStatus = '' | 'PENDING' | 'FULFILLED' | 'REJECTED'
export interface GoogleAddressComponent {
  long_name: string
  types: string[]
}

export interface Coordinates {
  lat: number
  long: number
}
