import axios from 'axios'
import { format, formatISO, setDate } from 'date-fns'

import { getConfig } from '../config'
import {
  SearchTerms,
  Appointment,
  Practitioner,
  Service,
  CalendarSlot,
  GroupedAppointment,
  PractitionerDetails,
  PractitionerLanguage,
  PractitionerPatientGroup,
  SearchParams,
  PersonalInformation,
  LocationDetails,
  ReservationParams,
  Reservation,
  AppointmentType,
  ResourceDetails,
  ObjectType,
  User,
} from '../types'
import { assertNever } from '../utils'

import {
  extractResult,
  decodeAppointments,
  groupAppointments,
  filterPastAppointments,
  filterFreeAppointments,
  parsePractitionerDetails,
  parseResourceDetails,
  parseNextAppointmentDate,
  parseFreeCalendarDays,
  filterOnlineTypes,
  filterServicesFromPrices,
} from './parsers'

const customerRequest = axios.create({ baseURL: getConfig().apiUrl })

export const search = (params: SearchParams): Promise<Appointment[]> =>
  customerRequest
    .get('/v1/appointments/search/timeslots', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
      params: {
        ...(params.date !== null && { date: format(params.date, 'yyyy-MM-dd') }),
        ...(typeof params.location !== 'undefined' && {
          'coordinates[latitude]': params.location[0].latitude,
          'coordinates[longitude]': params.location[0].longitude,
        }),
        ...(params.practitioner !== null && { practitioners: params.practitioner }),
        ...(params.service !== null && { medicalServiceIds: params.service }),
        ...(params.type !== null && { timeslotTypeIds: params.type }),
      },
    })
    .then(extractResult)
    .then(decodeAppointments)
    .then(filterPastAppointments)
    .then(filterFreeAppointments)

export const searchGrouped = (terms: SearchTerms): Promise<GroupedAppointment[]> =>
  search(terms).then((appointments) => groupAppointments(appointments, terms.timeOfDay))

export const calendarSearch = (
  params: SearchParams,
  start?: Date,
  end?: Date
): Promise<CalendarSlot[]> =>
  customerRequest
    .get('/v1/appointments/search/timeslots/calendar', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
      params: {
        ...(start && { timeStart: formatISO(start) }),
        ...(end && { timeEnd: formatISO(end) }),
        ...(params.date !== null && { date: format(params.date, 'yyyy-MM-dd') }),
        ...(params.practitioner !== null && { practitioners: params.practitioner }),
        ...(params.service !== null && { medicalServiceIds: params.service }),
        ...(params.type !== null && { timeslotTypeIds: params.type }),
      },
    })
    .then(extractResult)
    .then(parseFreeCalendarDays)

export const practitionerSearch = (query: string): Promise<Practitioner[]> =>
  customerRequest
    .get('/v1/appointments/search/practitioner', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
      params: {
        searchTerm: query,
      },
    })
    .then(extractResult)

export const getUser = (): Promise<User> =>
  customerRequest
    .get('/v1/users', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)
    .then((users) => users[0])
    .then((user) => ({ id: user.id }))

export const getServices = (): Promise<Service[]> =>
  customerRequest
    .get('/v1/appointments/medical-services', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getPractitionerDetails = (
  userId: number,
  practitionerId: number
): Promise<PractitionerDetails> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/practitioner/${practitionerId}`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)
    .then(parsePractitionerDetails)

export const getResourceDetails = (userId: number, resourceId: number): Promise<ResourceDetails> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/resource/${resourceId}`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)
    .then(parseResourceDetails)

export const getPerson = (userId: number): Promise<PersonalInformation> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/person`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getPractitionerLocation = (
  userId: number,
  locationId: number
): Promise<LocationDetails> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/practitioner/location/${locationId}`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getResourceLocation = (userId: number, locationId: number): Promise<LocationDetails> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/resource/location/${locationId}`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getLocation = (
  userId: number,
  objectType: ObjectType,
  locationId: number
): Promise<LocationDetails> =>
  objectType === 'practitioner'
    ? getPractitionerLocation(userId, locationId)
    : objectType === 'resource'
    ? getResourceLocation(userId, locationId)
    : assertNever(objectType)

export const reserve = (userId: number, params: ReservationParams): Promise<void> =>
  customerRequest.post(
    `/v1/users/${userId}/appointments/reservation`,
    {
      practitionerId: params.practitionerId,
      timeslotId: params.timeslotId,
      priceId: params.priceId,
      bookedReason: params.bookedReason,
    },
    {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    }
  )

export const getNextAvailableAppointment = (params: SearchParams): Promise<Date> =>
  calendarSearch({ ...params, date: setDate(params.date ?? new Date(), 1) }).then((slots) =>
    parseNextAppointmentDate(slots, params.date ?? new Date())
  )

export const getAppointment = (userId: number, appointmentId: number): Promise<Reservation> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/reservations/${appointmentId}`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const cancel = (
  userId: number,
  timeslotId: number
): Promise<{ discussionId: number | null }> =>
  customerRequest
    .post(
      `/v1/users/${userId}/appointments/cancel`,
      {
        timeslotId,
      },
      {
        headers: {
          Authorization: window.sessionStorage.getItem('mdhUserToken'),
        },
      }
    )
    .then(extractResult)

export const getReservations = (userId: number): Promise<Reservation[]> =>
  customerRequest
    .get(`/v1/users/${userId}/appointments/reservations`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getTypes = (): Promise<AppointmentType[]> =>
  customerRequest
    .get('/v1/appointments/timeslot-types', {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)
    .then(filterOnlineTypes)

export const getPractitionerLanguages = (practitionerId: number): Promise<PractitionerLanguage[]> =>
  customerRequest
    .get(`/v1/practitioners/${practitionerId}/languages`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getPractitionerPatientGroups = (
  practitionerId: number
): Promise<PractitionerPatientGroup[]> =>
  customerRequest
    .get(`/v1/practitioners/${practitionerId}/patient-groups`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)

export const getPractitionerServices = (practitionerId: number): Promise<number[]> =>
  customerRequest
    .get(`/v1/practitioners/${practitionerId}/services/prices`, {
      headers: {
        Authorization: window.sessionStorage.getItem('mdhUserToken'),
      },
    })
    .then(extractResult)
    .then(filterServicesFromPrices)
