import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { LocalizeProvider } from 'react-localize-redux'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import { theme } from '../../config'
import configureStore from '../../store'
import Loader from '../general/Loader/Loader'
import { TokenGate } from '../general/TokenGate/TokenGate'

import RootComponent from './RootComponent'

const App: React.FC = () => {
  const history = createBrowserHistory()
  const { store, persistor } = configureStore(history)

  return (
    <TokenGate>
      <ReduxProvider store={store}>
        <LocalizeProvider>
          <ConnectedRouter history={history}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <RootComponent />
                </BrowserRouter>
              </ThemeProvider>
            </PersistGate>
          </ConnectedRouter>
        </LocalizeProvider>
      </ReduxProvider>
    </TokenGate>
  )
}

export default App
