import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './components/App/App'
import { getConfig } from './config'
import { Firebase } from './utils'

const config = getConfig()

Sentry.init({
  dsn: 'https://b5cbcb3099b7431dbdfb555fa6054dfb@o561371.ingest.sentry.io/5984360',
  environment: config.environment,
  release: config.gitSHA,
})
Sentry.setTag('customer', config.customer)
Sentry.setTag('environment', config.environment)

Firebase.initialize()

ReactDOM.render(<App />, document.getElementById('root'))
