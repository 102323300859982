import { getConfig } from '../config'
import { FirebaseConfig } from '../config/base'
import { FirebaseAnalytics } from '../types'

declare global {
  interface Window {
    firebase?: {
      initializeApp?: (config: FirebaseConfig) => void
      analytics?: () => FirebaseAnalytics
    }
  }
}

let analyticsInstance: FirebaseAnalytics | undefined

export const initialize = (): void => {
  const config = getConfig()

  if (config.firebase.apiKey) {
    window.firebase?.initializeApp?.(config.firebase)
    analyticsInstance = window.firebase?.analytics?.()
  }
}

export const trackEvent = (event: string, payload: unknown): void => {
  if (getConfig().environment === 'development') {
    // eslint-disable-next-line no-console
    console.log('Firebase Tracking Debug', event, payload)
  } else {
    analyticsInstance?.logEvent(event, payload)
  }
}
