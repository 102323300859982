import { baseConfig, Config } from './base'

export const getConfig = (): Config => ({
  ...baseConfig,
  currency: {
    unit: '€',
    position: 'after',
    numberLocale: 'en-US',
  },
})
