import { parse as parseQuery } from 'query-string'
import React, { useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize, LocalizeContextProps } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect, withRouter, RouteComponentProps, Switch } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { getConfig } from '../../config'
import elTranslations from '../../locale/el.translations.json'
import enTranslations from '../../locale/en.translations.json'
import { RootState } from '../../state'
import { iniateLoad } from '../../state/general/general.actions'
import Loader from '../general/Loader/Loader'
import Appointment from '../routes/Appointment/Appointment'
import Recipient from '../routes/Recipient/Recipient'
import Search from '../routes/Search/Search'

import Style from './RootComponent.module.css'

const languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'ελληνικά',
    code: 'el',
  },
]

const getInitialLanguage = (): string | null => {
  const query = parseQuery(window.location.search)

  if (query.lang && languages.some((language) => language.code === query.lang)) {
    return query.lang as string
  } else {
    return null
  }
}

class InitLocaleClass extends React.Component<LocalizeContextProps> {
  constructor(props: LocalizeContextProps) {
    super(props)

    this.props.initialize({
      languages,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
      },
    })
    this.props.addTranslationForLanguage(enTranslations, 'en')
    this.props.addTranslationForLanguage(elTranslations, 'el')

    const initialLanguage = getInitialLanguage()
    if (initialLanguage) {
      this.props.setActiveLanguage(initialLanguage)
    }
  }

  public render() {
    return (
      <>
        {getConfig().environment === 'development' && (
          <ul
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              color: 'white',
              zIndex: 1000,
              margin: 0,
              padding: 0,
              listStyle: 'none',
            }}
          >
            {languages.map((lang) => (
              <li style={{ display: 'inline' }} key={lang.code}>
                <button onClick={() => this.props.setActiveLanguage(lang.code)}>{lang.name}</button>
              </li>
            ))}
          </ul>
        )}
        {this.props.children}
      </>
    )
  }
}

const InitLocale = withLocalize(InitLocaleClass)

const getCorrectAnimationDirection = (direction: 'forward' | 'back' | undefined = 'forward') => {
  switch (direction) {
    case 'back':
      return {
        enter: Style.pageEnterRight,
        enterActive: Style.pageEnterActiveRight,
        exit: Style.pageExitRight,
        exitActive: Style.pageExitActiveRight,
      }
    default:
      return {
        enter: Style.pageEnterLeft,
        enterActive: Style.pageEnterActiveLeft,
        exit: Style.pageExitLeft,
        exitActive: Style.pageExitActiveLeft,
      }
  }
}

const routes = [
  {
    path: '/search',
    Component: Search,
  },
  {
    path: '/recipient/:practitionerId',
    Component: Recipient,
  },
  {
    path: '/appointment/:appointmentId/:displayMode?',
    Component: Appointment,
  },
]

const Routes: React.FC<RouteComponentProps> = ({ history }: any) => {
  const dispatch = useDispatch()
  const ready = useSelector((state: RootState) => state.general.ready)
  const error = useSelector((state: RootState) => state.general.error)

  useEffect(() => {
    dispatch(iniateLoad.request())
  }, [dispatch])

  return (
    <InitLocale>
      {ready ? (
        <div className={Style.container}>
          {routes.map(({ path, Component }, index) => (
            <Route key={`route-${index}`} path={path}>
              {({ match: routematch }) => (
                <CSSTransition
                  in={routematch != null}
                  timeout={300}
                  classNames={getCorrectAnimationDirection(history?.location?.state?.direction)}
                  unmountOnExit
                >
                  <div className={Style.page}>
                    <Component />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
        </div>
      ) : error ? (
        <div className={Style.error}>Error: {error.message}</div>
      ) : (
        <Loader />
      )}
      <Switch>
        <Redirect exact from="/" to="/search/appointments" />
      </Switch>
    </InitLocale>
  )
}

export default withRouter(Routes)
