export interface FirebaseConfig {
  apiKey?: string
  appId?: string
  authDomain?: string
  databaseURL?: string
  measurementId?: string
  messagingSenderId?: string
  projectId?: string
  storageBucket?: string
}

interface BaseConfig {
  apiUrl: string
  customer: string
  environment: string
  firebase: FirebaseConfig
  gitSHA?: string
  testToken?: string
}

const firebaseEnvVars = {
  apiKey: 'REACT_APP_PUBLIC_FIREBASE_API_KEY',
  appId: 'REACT_APP_PUBLIC_FIREBASE_APP_ID',
  authDomain: 'REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN',
  databaseURL: 'REACT_APP_PUBLIC_FIREBASE_DATABASE_URL',
  measurementId: 'REACT_APP_PUBLIC_FIREBASE_MEASUREMENT_ID',
  messagingSenderId: 'REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID',
  projectId: 'REACT_APP_PUBLIC_FIREBASE_PROJECT_ID',
  storageBucket: 'REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET',
}

type FirebaseOption = keyof FirebaseConfig

// Options required for the Firebase to operate.
const requiredOptions: FirebaseOption[] = [
  'apiKey',
  'appId',
  'authDomain',
  'messagingSenderId',
  'projectId',
  'storageBucket',
]

const firebase: FirebaseConfig = (
  Object.entries(firebaseEnvVars) as Array<[FirebaseOption, string]>
).reduce(
  (acc, [option, variable]) => ({
    ...acc,
    [option]: process.env[variable] || '',
  }),
  {} as FirebaseConfig
)

// Make sure that all or none of the required options are defined.
requiredOptions.some((option) => firebase[option]) &&
  requiredOptions.forEach((option) => {
    if (!firebase[option]) {
      throw new Error(`Environment variable ${firebaseEnvVars[option]} not defined.`)
    }
  })

export interface Config extends BaseConfig {
  currency: {
    unit: string
    position: 'before' | 'after'
    numberLocale: string
  }
}

const requireEnv = (name: string): string => {
  const value = process.env[name]?.trim()

  if (!value) {
    throw new Error(`Environment variable ${name} not defined.`)
  }

  return value
}

export const baseConfig: BaseConfig = {
  apiUrl: requireEnv('REACT_APP_CUSTOMER_API'),
  customer: requireEnv('REACT_APP_CUSTOMER'),
  environment: requireEnv('NODE_ENV'),
  firebase,
  gitSHA: process.env.REACT_APP_GIT_SHA,
  testToken: process.env.REACT_APP_TEST_TOKEN,
}
